<h1 mat-dialog-title>{{ 'title.new_customer' | translate }}</h1>
<div mat-dialog-content class="mat-typography flex flex-col">
    <form [formGroup]="form" class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">
                {{ 'name' | translate }}
            </div>
            <sz-input formControlName="name" />
        </div>
        <div *ngIf="oems.length > 0">
            <div class="mat-subtitle-2">
                {{ 'customer.role.oem' | translate }}
            </div>
            <sz-select formControlName="oemId" [options]="oemOptions" />
        </div>
        <div *ngIf="dealers.length > 0">
            <div class="mat-subtitle-2">
                {{ 'customer.role.dealer' | translate }}
            </div>
            <sz-select formControlName="fromId" [options]="dealerOptions" />
        </div>
    </form>
    <sz-dialog-button-bar
        [primaryDisabled]="form.invalid"
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
