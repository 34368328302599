import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
import { CustomerRole } from 'src/app/services/constants';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { SelectOption } from 'src/app/settings/settings.model';
import {
    getCustomerId,
    getCustomerRole,
    getOemId,
} from 'src/app/shared/common';
import {
    CreateOrganization,
    Organization,
} from 'src/app/shared/models/customer';
import { palette } from 'src/app/shared/palette';

@Component({
    templateUrl: './create-customer-dialog.component.html',
})
export class CreateCustomerDialog implements AfterContentChecked, OnInit {
    form = new FormGroup({
        name: new FormControl(null, Validators.required),
        role: new FormControl(null),
        fromId: new FormControl(null),
        oemId: new FormControl(null),
    });

    oems: Organization[] = [];
    dealers: Organization[] = [];
    oemOptions: SelectOption[];
    dealerOptions: SelectOption[];

    get customerId() {
        return getCustomerId();
    }

    get customerRole() {
        return getCustomerRole();
    }

    constructor(
        public dialogRef: MatDialogRef<CreateCustomerDialog>,
        private snackBar: SnackBarService,
        private changeDetectorRef: ChangeDetectorRef,
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: { role: CustomerRole },
    ) {
        this.setInitialData();
    }

    ngAfterContentChecked() {
        // Force detect changes after check, to avoid ExpressionChangedAfterItHasBeenCheckedError
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit(): void {
        this.form.get('oemId').valueChanges.subscribe((oemId) => {
            this.loadDealerOptions(oemId);
        });
    }

    async setInitialData() {
        await ensureLoaded([this.api.organizations]);
        // Set initial data based on page and customer role
        switch (this.data.role) {
            case CustomerRole.Administrator: {
                this.form.get('role').setValue(CustomerRole.Administrator);
                this.form.get('fromId').setValue(this.customerId);
                break;
            }
            case CustomerRole.OEM: {
                this.form.get('role').setValue(CustomerRole.OEM);
                if (this.customerRole == CustomerRole.Administrator) {
                    this.form.get('fromId').setValue(this.customerId);
                }
                break;
            }
            case CustomerRole.Dealer: {
                this.form.get('role').setValue(CustomerRole.Dealer);
                if (this.customerRole <= CustomerRole.Administrator) {
                    this.oems = this.getCustomers(CustomerRole.OEM);
                    this.oemOptions = this.oems.map((oem) => ({
                        value: oem.id,
                        name: oem.name,
                    }));
                    this.form.get('oemId').addValidators(Validators.required);
                }
                if (this.customerRole == CustomerRole.OEM) {
                    this.form.get('fromId').setValue(this.customerId);
                    this.form.get('oemId').setValue(this.customerId);
                }
                break;
            }
            case CustomerRole.Client: {
                const oemControl = this.form.get('oemId');
                if (this.customerRole <= CustomerRole.OEM) {
                    this.dealers = this.getCustomers(CustomerRole.Dealer);
                    this.loadDealerOptions();
                    this.form.get('fromId').addValidators(Validators.required);

                    if (this.customerRole <= CustomerRole.Administrator) {
                        this.oems = this.getCustomers(CustomerRole.OEM);
                        this.oemOptions = this.oems
                            // Only show OEMs that have dealers
                            .filter((oem) =>
                                this.dealers.some((d) => d.oemId === oem.id),
                            )
                            .map((oem) => ({ value: oem.id, name: oem.name }));
                        oemControl.addValidators(Validators.required);
                    } else if (this.customerRole == CustomerRole.OEM) {
                        oemControl.setValue(this.customerId);
                    }
                } else if (this.customerRole == CustomerRole.Dealer) {
                    const oemId = getOemId();
                    oemControl.setValue(oemId);
                    this.form.get('fromId').setValue(this.customerId);
                }
                this.form.get('role').setValue(CustomerRole.Client);
                break;
            }
        }
    }

    getCustomers(role) {
        return this.api.organizations.current({ role });
    }

    loadDealerOptions(oemId?: number) {
        this.dealerOptions = this.dealers
            .filter((dealer) => (oemId ? dealer.oemId == oemId : true))
            .map((dealer) => ({
                value: dealer.id,
                name: dealer.name,
            }));
    }

    async handleSave() {
        if (
            this.customerRole <= CustomerRole.Administrator &&
            this.data.role == CustomerRole.Dealer
        ) {
            this.form.get('fromId').setValue(this.form.get('oemId').value);
        }

        const data: CreateOrganization = {
            name: this.form.get('name').value,
            role: +this.form.get('role').value,
            theme: palette.Default,
        };

        if (this.form.get('fromId').value) {
            data.fromId = +this.form.get('fromId').value;
        }
        if (this.form.get('oemId').value) {
            data.oemId = +this.form.get('oemId').value;
        }
        try {
            const response = await this.api.organizations.create(data);
            this.dialogRef.close(response['id']);
        } catch (error) {
            let term = 'dialog.error.customer_creation';
            if (
                error.error &&
                error.error['message'] == 'Customer name must be unique.'
            ) {
                term = 'dialog.error.customer_exists';
            }
            this.snackBar.open(term);
        }
    }
}
